import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(app)/components/account-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/(app)/components/content-layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CreateNewFunctionButton"] */ "/vercel/path0/app/(app)/components/header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.10_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.10_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/logo.svg");
